

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="24">
            <el-button type="primary" @click="dataBinding()">刷新</el-button>
            <el-button type="primary" @click="handleEditModel(0)">新增</el-button>
          </el-col>
        </el-row>
      </div>

      <el-table :data="tableData" stripe fit>
        <el-table-column prop="title" label="分类名称">
        </el-table-column>
        <el-table-column prop="sort" label="排序">
        </el-table-column>

        <el-table-column prop="enable" label="是否启用">
          <template slot-scope="scope">
            <el-tag :type="scope.row.enable ? 'primary' : 'info'" disable-transitions>
              {{scope.row.enable?'启用':'禁用'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.isSuper" @click="handleEditModel(scope.row)" type="text">编辑</el-button>
            <el-button v-if="!scope.row.isSuper" @click="handleDeleteModel(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-t16 text-right">

      </div>

    </el-card>
  </div>
</template>
<script>
import { getList, deleteModel } from '@/service/article_category.js';

export default {
  data() {
    return {
      delClickVisibal: false,
      loading: false,
      searchOpen: false,
      totalCount: 0,
      searchForm: {
        type: 1

      },
      tableData: []
    };
  },
  created() {
    if (this.$route.params.type1) {
      this.searchForm.type = 1;
    }
    else if (this.$route.params.type2) {
      this.searchForm.type = 2;
    }

    this.dataBinding();
  },
  methods: {
    //跳转编辑案例页
    handleEditModel(row) {
      this.$router.push({
        name: 'categoryedit',
        query: { id: row.id, type: this.searchForm.type }
      });
    },
    //加载页面数据
    dataBinding() {
      this.loading = true;
      getList(this.searchForm).then(res => {
        this.tableData = res;
        this.loading = false;
      });
    },
    //跳转新增页
    handleEdit() {
      this.$router.push({ name: 'categoryedit', query: { type: this.searchForm.type } });
    },
    //删除
    handleDeleteModel(row) {
      this.loading = true;
      deleteModel(row.id).then(res => {
        if (res === true) {
          this.dataBinding();
        }
        else {
          this.$message("删除操作失败");
        }
        this.loading = false;
      });
    }
  }
};
</script>